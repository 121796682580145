import React from "react"
import { Button, Container } from "../ui"

export default function Hero() {
  return (
    <div className="items-center bg-primary h-[60vh]">
      <div className="relative  h-[60vh] overflow-hidden ">
        <video
          //width="80%"
          height="100%"
          autoPlay
          muted
          loop
          className="absolute z-0 right-0 hidden md:block"
        >
          <source src="https://cms-icon.logisoft.io/wp-content/uploads/2022/06/video1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
		<div className="bg-primary  w-[50%] h-full z-10 absolute left-0 "></div>
		<div className="relative z-20">
			<Container>
				<div className="max-w-md h-[60vh] flex justify-center flex-col ">
					<h2 className="text-5xl text-white font-semibold leading-[1.2] mb-6">There’s a smarter way to do Freight Forwarding</h2>
					<p className="text-lg text-white text-opacity-70">
					Comprehensive digital services that deliver peace of mind in every
					move.
					</p>
					<span className="mt-8">
						<Button variant="white" size="large" to="/request-a-quote">Get Started</Button>
					</span>
				</div>
			</Container>
		</div>
      </div>
    </div>
  )
}
