import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import Hero from "../components/homepage/hero"
import LeftImageText from "../components/elements/LeftImageText"
import EServices from "../components/homepage/eServices"
import MobileAppCTA from "../components/homepage/MobileAppCTA"
import Stats from "../components/homepage/Stats"
import News from "../components/homepage/News"
import RightImageText from "../components/elements/RightImageText"
import IMG1 from "./../assets/images/log1.jpg"
import IMG2 from "./../assets/images/modes1.jpeg"
import PrimaryCTA from "../components/elements/PrimaryCTA"

export default function Homepage(props) {
  const { homepage } = props.data

  return (
    <Layout {...homepage}>
      <Hero />
      <LeftImageText
        title="Going beyond typical Freight Forwarding services"
        button={{ label: "Explore Our Services", to: "/services" }}
        image={IMG1}
      >
        For over a decade, we have been trusted by families and corporations to
        move their treasured cargos whether down the street or across
        continents.
        <br />
        <br />
        Our freight forwarding services include customs clearance, cargo
        insurance, procurement services and others, all of which are coupled
        with technology to deliver premium digital solutions for a far better
        experience.
      </LeftImageText>
      <EServices />
      <MobileAppCTA />
      {/* <News /> */}
      {/* <Stats /> */}
      <RightImageText
        title="Need a move?"
        button={{ label: "Discover Our Transport Modes", to: "/by-transport-modes" }}
        image={IMG2}
        link={{ label: "Learn More About our Shipments", to: "/shipments" }}
      >
        Have a car, light aircraft, construction vehicle, heavy machine, boat,
        or even a standard-sized container that you want to ship? Let us help!
        <br />
        <br />
        Naturally, different cargoes have different needs which is why not only
        do we cover the 3 transport modes, but we also provide a 4th option!
      </RightImageText>
      <PrimaryCTA
        title="Ready to Get Started?"
        subtitle="One of our experts would love to get in touch with you to give you a thorough understanding of our services and solutions"
        button={{ label: "Get Started", to: "/contact" }}
      />

     
    </Layout>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
      image {
        id
        url
      }
      # blocks: content {
      #   id
      #   blocktype
      #   ...HomepageHeroContent
      #   ...HomepageFeatureListContent
      #   ...HomepageCtaContent
      #   ...HomepageLogoListContent
      #   ...HomepageTestimonialListContent
      #   ...HomepageBenefitListContent
      #   ...HomepageStatListContent
      #   ...HomepageProductListContent
      # }
    }
  }
`
